body {
  background: #051537;
  margin: 0;
  color: #fff;
    font-family: 'Roboto Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-top: 3px solid #1CE7C2;
}

code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.MuiDialogTitle-root {
  background-color: #212121 !important;
}

.MuiDialogTitle-root .MuiTypography-root {
  font-family: 'Poppins' !important;
  font-weight: 400;
}